import React from 'react'
import SecOne from '../Components/Home/SecOne'
import SecTwo from '../Components/Home/SecTwo'

function HomePage() {
  return (
   <div className='container-fluid'>
    <div className='row'>
        <SecOne/>
        <SecTwo/>
    </div>
   </div>
  )
}

export default HomePage