import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

function SecOne() {
    return (
        <div className='container-fluid midsec'>
            <div className='container'>
                <div className='row'>
                    <div className='col-sm-12'>
                        <p className='upper'>Wellness Hub is a community that uplifts and destigmatizes mental health by bringing
                            jokes, quotes, songs, and images to uplift even in the toughest times. </p>

                        <div className='row desktopp'>
                            <div className='col-sm-3'>
                                <h2>1 in 4</h2>
                                <p>people globally face
                                    mental health challenges
                                    every single day</p>
                            </div>

                            <div className='col-sm-3'>
                                <h2>75%</h2>
                                <p>of people with mental health
                                    say that they avoid or have
                                    avoided seeking help due to
                                    stigma and discrimination.</p>
                            </div>

                            <div className='col-sm-3'>
                                <h2>Half</h2>
                                <p>of all mental health
                                    conditions start by 14
                                    years of age, but most
                                    cases are undetected and
                                    untreated.</p>
                            </div>

                            <div className='col-sm-3'>
                                <h2>Over 264
                                    million</h2>
                                <p>people worldwide are affected by depression and 284 million have an anxiety disorder</p>
                            </div>
                        </div>


                        <div className='row mobile'>
                            <div className='col-sm-12'>
                                <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                                    <SwiperSlide>
                                        <div className='col-sm-3'>
                                            <h2>1 in 4</h2>
                                            <p>people globally face
                                                mental health challenges
                                                every single day</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='col-sm-3'>
                                            <h2>75%</h2>
                                            <p>of people with mental health
                                                say that they avoid or have
                                                avoided seeking help due to
                                                stigma and discrimination.</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='col-sm-3'>
                                            <h2>Half</h2>
                                            <p>of all mental health
                                                conditions start by 14
                                                years of age, but most
                                                cases are undetected and
                                                untreated.</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className='col-sm-3'>
                                            <h2>Over 264
                                                million</h2>
                                            <p>people worldwide are affected by depression and 284 million have an anxiety disorder</p>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>

                        <div className='col-sm-12'>
                            <p className='lower'>Verizon employees came together to submit the inspirations that uplift their day to day mental health.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SecOne