import React, { useEffect, useState } from "react";
import { Button, Form, Input, Radio, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const { Option } = Select;

const StepOne = ({ onFinish, handleClose }) => {
  const [AllState, setAllState] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [formData, setFormData] = useState({
    ageGroup: "",
    state: "",
    website: "",
    uplifted: "",
    mentalhealth: "",
    healthresource: "",
    suggestions: "",
  });

  const ageGroupOptions = [
    { label: "0-10", value: "0-10" },
    { label: "11-14", value: "11-14" },
    { label: "15-18", value: "15-18" },
    { label: "19-23", value: "19-23" },
    { label: "24-35", value: "24-35" },
    { label: "36-64", value: "36-64" },
    { label: "65+", value: "65+" },
  ];

  const upliftedOptions = [
    { label: "Strongly agree", value: "Strongly agree" },
    { label: "Somewhat agree", value: "Somewhat agree" },
    { label: "Neutral or no effect", value: "Neutral or no effect" },
    { label: "Somewhat disagree", value: "Somewhat disagree" },
    { label: "Strongly disagree", value: "Strongly disagree" },
  ];

  const mentalhealthOptions = [
    { label: "Very positive", value: "Very positive" },
    { label: "Somewhat positive", value: "Somewhat positive" },
    { label: "Neutral", value: "Neutral" },
    { label: "Somewhat negative", value: "Somewhat negative" },
    { label: "Very negative", value: "Very negative" },
  ];

  const healthresourceOptions = [
    { label: "Very likely", value: "Very likely" },
    { label: "Somewhat likely", value: "Somewhat likely" },
    { label: "Neutral", value: "Neutral" },
    { label: "Not likely", value: "Not likely" },
    { label: "Never", value: "Never" },
  ];

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const fetchStates = async () => {
    try {
      const res = await axios.get(
        `https://server.wellnessgenerator.org/api/state?name=${searchValue}`
      );
      setAllState(res?.data?.data?.items || []);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  useEffect(() => {
    fetchStates();
  }, [searchValue]);

  console.log(searchValue);

  const handleSubmit = async () => {
    const GetTotalJokeAndQuoteCount = Cookies.get("JokeSeen");
    Cookies.get("JokeSeen", Number(GetTotalJokeAndQuoteCount) + 1, {
      expires: 1,
    });
    Cookies.set("SurveyDone", true, { expires: 1 });

    const [startAgeStr, endAgeStr] = formData?.ageGroup.split("-");
    const startAge = parseInt(startAgeStr, 10);
    const endAge = parseInt(endAgeStr, 10);
    const Data = {
      StartAge: startAge,
      EndAge: endAge,
      TotalAgeRange: formData?.ageGroup,
      State: formData?.state,
      HowDidYouHearAboutThis: formData?.website,
      AfterUsingReview: formData?.uplifted,
      DidTheResourcesHaveThePositiveEffect: formData?.mentalhealth,
      LikelyToUseAgain: formData?.healthresource,
      WeLoveHearing: formData?.suggestions,
    };

    try {
      await axios.post(
        "https://server.wellnessgenerator.org/api/SurveyResponseRoutes/AddSurveyResponse",
        Data
      );
      Swal.fire({
        icon: "success",
        title: "Thanks...",
        text: "Thank You For Submitting the Form",
      });
      handleClose();
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };

  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <Form onFinish={handleSubmit} layout="vertical">
            <Form.Item
              name="ageGroup"
              className="ageegroupp"
              label="Choose your age group"
              rules={[
                {
                  required: true,
                  message: "Please select your age group!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => handleChange("ageGroup", e.target.value)}
                buttonStyle="solid"
                value={formData.ageGroup}
              >
                {ageGroupOptions.map((option) => (
                  <Radio.Button key={option.value} value={option.value}>
                    {option.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="state"
              label="Choose your state"
              rules={[
                {
                  required: true,
                  message: "Please select your tate!",
                },
              ]}
            >
              <Select
                placeholder="Select a state"
                onChange={(value) => handleChange("state", value)}
                value={formData.state}
                showSearch
                allowClear
                onSearch={handleSearch}
                filterOption={(inputValue, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(inputValue.toLowerCase()) >= 0
                }
              >
                {AllState?.map((item) => (
                  <Option key={item?._id} value={item?._id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="website"
              label="How did you hear about this website?"
              rules={[
                {
                  required: true,
                  message: "Please enter how you heard about this website!",
                },
              ]}
            >
              <Input
                placeholder="Enter how you heard about this website"
                onChange={(e) => handleChange("website", e.target.value)}
                value={formData.website}
              />
            </Form.Item>

            <Form.Item
              name="uplifted"
              label="After using the Wellness Hub, I felt uplifted:"
              className="ageegroupp"
              rules={[
                {
                  required: true,
                  message:
                    "Please select how you felt after using the Wellness Hub!",
                },
              ]}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => handleChange("uplifted", e.target.value)}
                value={formData.uplifted}
              >
                {upliftedOptions.map((option) => (
                  <Radio.Button key={option.value} value={option.value}>
                    {option.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="mentalhealth"
              label="Did the resources on the Wellness Hub have a positive effect on your mental health?"
              className="ageegroupp"
              rules={[
                {
                  required: true,
                  message: "Please select the effect on your mental health!",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => handleChange("mentalhealth", e.target.value)}
                buttonStyle="solid"
                value={formData.mentalhealth}
              >
                {mentalhealthOptions.map((option) => (
                  <Radio.Button key={option.value} value={option.value}>
                    {option.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="healthresource"
              label="How likely are you to use this website as a positive mental health resource in the future?"
              className="ageegroupp"
              rules={[
                {
                  required: true,
                  message:
                    "Please select likelihood to use this website in the future!",
                },
              ]}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => handleChange("healthresource", e.target.value)}
                value={formData.healthresource}
              >
                {healthresourceOptions.map((option) => (
                  <Radio.Button key={option.value} value={option.value}>
                    {option.label}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="suggestions"
              label="We love hearing from you! Do you have any additional comments or suggestions?"
              // rules={[
              //   {
              //     required: true,
              //     message: "Please enter your comments or suggestions!",
              //   },
              // ]}
            >
              <Input
                placeholder="Comments or suggestions"
                onChange={(e) => handleChange("suggestions", e.target.value)}
                value={formData.suggestions}
              />
            </Form.Item>

            <Form.Item className="mt-3">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
