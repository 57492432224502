import React, { useState, useEffect, useRef } from "react";
import Button1 from "../../Assets/as3.png";
import Button2 from "../../Assets/as2.png";
import Arrow from "../../Assets/arrow.PNG";
import { jokesnqotes } from "../../Data/Data";
import { Bounce } from "react-awesome-reveal";
import { Fade } from "react-awesome-reveal";
import Lottie from "lottie-react";
import Inspiration from "../../Assets/Inspiration.json";
import Joke from "../../Assets/Joke.json";
import Static from "../../Assets/Static.json";
import Main from "../StepQuestion/Main";
import Modal from "react-bootstrap/Modal";
import SpotifyWebPlayer from "react-spotify-web-playback";
import Button from "react-bootstrap/Button";
import { SpotifyApiContext, SpotifyApiAxiosContext } from "react-spotify-api";
import Cookies from "js-cookie";
import axios from "axios";
function SecTwo() {
  const token =
    "BQCtnMZBY8W7n2LtIGlRA5bnu-0HfX7OMb4-njQzhdRgcWZD7h4PMXjkLcVDeINxW3w3nPVphAejKacA6c1xNvUSOKnb2ilk_Ff_XwL_tEKhnPR2QaLGdcjGPkxwd-SDq0tj9CSTOKbfp8-K4n0orZIqusUTDT81WbFWkHrYh6LFwUFulNOL2o0QkrhlMOBXBIQRzKOTH_lT8ow3PBULlte706tGpa_H5SMdjCRbzGui9bc_VA";
  const [displayType, setDisplayType] = useState("static");
  const [displayedItem, setDisplayedItem] = useState(null);
  const [animationKey, setAnimationKey] = useState(0);
  const [isAnimationStopped, setIsAnimationStopped] = useState(false);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);
  const [shouldLoadStatic, setShouldLoadStatic] = useState(false);
  const [isDataVisible, setIsDataVisible] = useState(true);
  const [JokeSeen, setJokeSeen] = useState(0);
  const [SurveyDone, setSurveyDone] = useState(
    Cookies.get("SurveyDone") || false
  );
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (!Cookies.get("SurveyDone")) {
      // localStorage.setItem("SurveyDone", false);
      Cookies.set("SurveyDone", false, { expires: 1 });
    }
    if (!Cookies.get("JokeSeen")) {
      // localStorage.setItem("JokeSeen", JokeSeen);
      Cookies.set("JokeSeen", JokeSeen, { expires: 1 });
    }
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [ApiData, setApiData] = useState([]);
  const scrollableRef = useRef(null);

  useEffect(() => {
    setAnimationKey((prevKey) => prevKey + 1);
    setIsAnimationStopped(false);
    setDisplayedItem({
      Title: "",
      Description: "",
      type: "",
      BackgroundColor: "",
    });
  }, []);

  const shuffleData = (data) => {
    return data.slice().sort(() => Math.random() - 0.5);
  };

  const disableButtons = () => {
    setIsButtonsDisabled(true);
    setTimeout(() => {
      setIsButtonsDisabled(false);
    }, 6500);
  };

  const imgscroll = () => {
    window.scrollBy({
      top: 5000,
      left: 0,
      behavior: "smooth",
    });
  };
  const handleLeftButtonClick = async () => {
    setDisplayType("joke");
    if (SurveyDone == false) {
      setJokeSeen(JokeSeen + 1);
      // alert(JokeSeen + 1);
      // alert(Cookies.get("JokeSeen", JokeSeen));
      Cookies.set("JokeSeen", JokeSeen, { expires: 1 });
    }
    const Res = await axios.get(
      `${process.env.REACT_APP_API_URL}Joke/GetRandomJoke`
    );

    setTimeout(() => {
      setDisplayedItem(Res?.data?.result[0]);
    }, 6500);

    setIsAnimationStopped(true);
    setShouldLoadStatic(true); // Set to true to load static after 11 seconds
    setIsDataVisible(false);
  };

  const handleRightButtonClick = async () => {
    setDisplayType("quote");
    if (SurveyDone == false) {
      setJokeSeen(JokeSeen + 1);
      Cookies.set("JokeSeen", JokeSeen, { expires: 1 });
    }
    const Res = await axios.get(
      `${process.env.REACT_APP_API_URL}Joke/GetRandomQuote`
    );

    setTimeout(() => {
      setDisplayedItem(Res?.data?.result[0]);
    }, 6500);

    setIsAnimationStopped(true);
    // disableButtons();
    setShouldLoadStatic(true); // Set to true to load static after 11 seconds
    setIsDataVisible(false);
  };
  // const filledsurvey = localStorage.getItem("JokeSeen");

  useEffect(() => {
    if (isAnimationStopped) {
      setTimeout(() => {
        setIsAnimationStopped(false);
        if (shouldLoadStatic) {
          setDisplayType("static");
          setShouldLoadStatic(false);
          setIsDataVisible(true);
          // handleShow();
          // if (filledsurvey == 2) {
          //   handleShow();
          // }
        }
      }, 6500);
    }
  }, [isAnimationStopped, shouldLoadStatic]);
  useEffect(() => {
    if (Cookies.get("JokeSeen") >= 2 && Cookies.get("SurveyDone") != "true") {
      console.log(Cookies.get("JokeSeen"), "useffect");
      console.log(Cookies.get("SurveyDone"), "useffect");
      handleShow();
    }
  }, [JokeSeen]);

  return (
    <div className="container-fluid animation">
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <button
              className="left"
              onClick={() => {
                imgscroll();
                handleLeftButtonClick();
              }}
              disabled={isButtonsDisabled}
            >
              <img src={Button1} alt="asd" />
            </button>
          </div>
          <div className="col-sm-6 ">
            <button
              className="right"
              onClick={() => {
                imgscroll();
                handleRightButtonClick();
              }}
              disabled={isButtonsDisabled}
            >
              <img src={Button2} alt="asd" />
            </button>
          </div>

          <div className="col-sm-12">
            {displayedItem && (
              <Lottie
                animationData={
                  displayType === "joke"
                    ? Joke
                    : displayType === "quote"
                    ? Inspiration
                    : Static
                }
                className="load1"
                isStopped={isAnimationStopped}
                loop={false}
                style={{
                  marginTop: "30px",
                }}
              />
            )}
          </div>

          {displayedItem && isDataVisible && (
            <div className="qnj">
              <Bounce up key={animationKey}>
                <div className="col-sm-12 jokes ">
                  <div
                    className="qnj"
                    style={{ backgroundColor: displayedItem.BackgroundColor }}
                  >
                    {displayedItem?.Title ? (
                      <h2>{displayedItem?.Title}</h2>
                    ) : null}
                    {displayedItem.Description ? (
                      <p>{displayedItem.Description}</p>
                    ) : null}
                    {displayedItem.url && (
                      <iframe
                        title="Spotify Player"
                        src={displayedItem.url}
                        width="300"
                        height="380"
                        frameBorder="0"
                        allowtransparency="true"
                        allow="encrypted-media"
                      ></iframe>
                    )}
                    {displayedItem.Media ? (
                      <img
                        src={`https://server.wellnessgenerator.org${displayedItem?.Media}`}
                        alt={displayedItem.Title}
                        className="apiImage"
                      />
                    ) : null}
                  </div>
                </div>
              </Bounce>
            </div>
          )}

          <div className="col-sm-12 arrow" onClick={imgscroll}>
            {displayedItem && isDataVisible && (
              <>
                {displayedItem.JokeTypeName == "joke" && (
                  <Fade>
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    <p>
                      One in four people globally face mental health challenges.
                      We seek to create a community that supports mental health
                      through education and awareness
                    </p>
                  </Fade>
                )}

                {displayedItem.JokeCategoryName == "text" && (
                  <Fade>
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    <p>
                      Positive quotes serve as reminders to stay hopeful, focus
                      on the bright side of life, and embrace a mindset of
                      optimism and gratitude. These quotes are designed to help
                      you find strength and inspiration.
                    </p>
                  </Fade>
                )}
                {displayedItem.JokeCategoryName == "song" && (
                  <Fade>
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    <p>
                      Songs can inspire strength, perseverance, and the ability
                      to bounce back from challenges. These songs often have
                      empowering lyrics and uplifting melodies that encourage
                      listeners to stay strong and resilient.{" "}
                    </p>
                  </Fade>
                )}

                {displayedItem.JokeCategoryName == "image" && (
                  <Fade>
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>{" "}
                    <p>
                      Images can promote mindfulness for mental health by
                      providing a visual anchor for meditation, facilitating
                      relaxation and focus on the present moment. Depending on
                      the subject matter, they can evoke relaxing or uplifting
                      emotions.
                    </p>
                  </Fade>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal show={show}>
        <Modal.Header>Share your feedback !</Modal.Header>
        <Modal.Body className="new-modal">
          <Main handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SecTwo;
