import './Style/Style.css';
import { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderInclude from './Components/Header/HeaderInclude';
import FooterInclude from './Components/Footer/FooterInclude';
import HomePage from './Pages/HomePage';

function App() {
console.log(process.env.REACT_APP_API_URL, "<====environment");
  return (
    <Fragment>
      <HeaderInclude/>

      <HomePage/>
      
      <FooterInclude/>
    </Fragment>
  );
}

export default App;
