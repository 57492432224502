import React, { Fragment, useState } from "react";
import Logo from "../../Assets/logo.png";
import Modal from "react-bootstrap/Modal";
import Main from "../StepQuestion/Main";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

function HeaderInclude() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOpen = () => {
    if (Cookies.get("SurveyDone") == "true") {
      Swal.fire({
        icon: "warning",
        title: "Come back tomorrow !",
      });
    } else {
      handleShow();
    }
  };

  console.log(Cookies.get("SurveyDone"));
  return (
    <Fragment>
      <button className="offcanve-css" onClick={handleOpen}>
        Feedback
      </button>
      <div className="container header">
        <div className="row">
          <div className="col-sm-12">
            <img src={Logo} alt="asd" />
            <b>
              {" "}
              <p>
                Your source for uplifting <span>jokes</span>, quotes, songs, and
                images.
              </p>
            </b>
          </div>
        </div>
      </div>

      <Modal show={show}>
        <Modal.Header>Share your feedback !</Modal.Header>
        <Modal.Body className="new-modal">
          <Main handleClose={handleClose} />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}

export default HeaderInclude;
