import React, { Fragment } from 'react'
import Footerlogo from "../../Assets/footerlogo.png"

function FooterInclude() {
  return (
   <Fragment>
    <div className='container-fluid footer'>
        <div className='row'>
            <div className='col-sm-12'>
                <span>Powered by: </span>
                <img src={Footerlogo} alt='asd'/>
            </div>
        </div>
    </div>
   </Fragment>
  )
}

export default FooterInclude